import Head from 'next/head'

import {
  useStoryblokState,
  getStoryblokApi,
  StoryblokComponent,
  ISbStoryData,
  ISbStoryParams,
} from '@storyblok/react'
import {GetStaticPathsResult} from 'next'
import {ContextParams} from '../types/api-types'
import Layout from '../components/Layout/Layout'
import {refreshTranslations} from '../translations'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import Script from 'next/script'
import {useSession} from 'next-auth/react'
import {
  MainMenuSlugAlternates,
  constructMainMenuSlugAlternates,
  refreshMainMenuSlugTranslations,
} from 'mainMenuSlugTranslations'

export type pageTypes = {
  story: ISbStoryData
  mainMenu: ISbStoryData
  mainMenuSlugAlternates: MainMenuSlugAlternates
  footer: ISbStoryData
  errorPage?: ISbStoryData
  authPage: ISbStoryData
  locale: string
}

type pathTypes = {
  locales: string[]
}

export default function Page({
  story,
  mainMenu,
  mainMenuSlugAlternates,
  footer,
  errorPage,
  locale,
  authPage,
}: pageTypes) {
  story = useStoryblokState(story, {language: locale})!

  const session = useSession()

  const translatedTitle = story.translated_slugs?.filter(
    value => value.lang === locale,
  )[0]
  const title = story
    ? translatedTitle
      ? translatedTitle.name
      : story.name
    : 'DATS 24'

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
        />
      </Head>

      {session.status === 'authenticated' && (
        <Script
          id="onetrust"
          dangerouslySetInnerHTML={{
            __html: `(function(a,b,c,d){
          a='${process.env.NEXT_PUBLIC_ONETRUST_URL}';
          b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
          a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
          })();`,
          }}
        />
      )}
      <div id="modal-root"></div>
      <Layout
        mainMenu={mainMenu}
        mainMenuSlugAlternates={mainMenuSlugAlternates}
        defaultSlug={story.default_full_slug}
        translatedSlugs={story.translated_slugs}
        footer={footer}
        errorPage={errorPage}
        authPage={authPage}
      >
        <StoryblokComponent blok={story.content} />
      </Layout>
    </>
  )
}

export async function getStaticPaths({
  locales,
}: pathTypes): Promise<GetStaticPathsResult> {
  // Refresh our datasource translations.
  await refreshTranslations(locales)
  await refreshMainMenuSlugTranslations(locales)

  const storyblokApi = getStoryblokApi()
  let {data} = await storyblokApi.get('cdn/links/', {resolve_links: 'url'})

  const paths: GetStaticPathsResult['paths'] = []

  Object.keys(data.links).forEach(linkKey => {
    const link = data.links[linkKey]
    // do not create a route for folders
    if (
      link.is_folder ||
      link.real_path.includes('singletons') ||
      link.real_path.includes('alerts') ||
      link.real_path.includes('marta') ||
      link.real_path.includes('not-found') ||
      link.real_path.includes('session-expired')
    ) {
      return
    }

    // get array for slug because of catch all
    const slug = link.slug
    let splittedSlug: string[] | undefined = slug.split('/')
    if (slug === 'home') splittedSlug = undefined
    paths.push({
      params: {slug: splittedSlug},
      locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
    })

    // Create link for each translated slug (= the alternate field).
    link.alternates?.forEach((alternate: {path: any; lang: any}) => {
      const slug = alternate.path
      let splittedSlug: string[] | undefined = slug.split('/')
      if (slug === 'home') splittedSlug = undefined
      paths.push({params: {slug: splittedSlug}, locale: alternate.lang})
    })
  })

  return {
    paths,
    fallback: 'blocking',
  }
}

export async function getStaticProps({params, locale}: ContextParams) {
  let slug = params.slug ? params.slug.join('/') : 'home'
  let mainMenuSlug = 'singletons/mainmenu'
  let footerSlug = 'singletons/footer'
  let errorSlug = 'server-error-page'
  let authPageSlug = 'auth-page'

  let sbParams: ISbStoryParams = {
    version: 'published',
    resolve_links: 'story',
    language: locale,
    cv: Date.now(),
  }

  await refreshTranslations([locale])
  await refreshMainMenuSlugTranslations([locale])

  const storyblokApi = getStoryblokApi()
  let storyblokData = null

  try {
    let {data} = await storyblokApi.get(`cdn/stories/${slug}`, sbParams)
    storyblokData = data
  } catch (err) {
    return {
      notFound: true,
      revalidate: process.env.APP_ENV === 'prd' ? 5 * 60 : 60, // prd
    }
  }

  let story = storyblokData ? storyblokData.story : false
  let errorPage = await storyblokApi.get(`cdn/stories/${errorSlug}`, sbParams)
  story.content.errorPage = errorPage.data

  if (slug === 'cards' || slug === 'kaarten' || slug === 'cartes') {
    // Also fetch the fuel and charging cards detail pages, because we don't want to navigate
    // to a separate URL to display the details.
    let fuelCardDetailsPage = await storyblokApi.get(
      `cdn/stories/cards/fuel-card-details-page`,
      sbParams,
    )
    let chargingCardDetailsPage = await storyblokApi.get(
      `cdn/stories/cards/charging-card-details-page`,
      sbParams,
    )
    story.content.fuelCardDetails = fuelCardDetailsPage.data
    story.content.chargingCardDetails = chargingCardDetailsPage.data
    story.content.fuelCardDetails.story.content.errorPage = errorPage.data
    story.content.chargingCardDetails.story.content.errorPage = errorPage.data
  }

  let mainMenu = await storyblokApi.get(`cdn/stories/${mainMenuSlug}`, sbParams)
  let footer = await storyblokApi.get(`cdn/stories/${footerSlug}`, sbParams)
  let authPage = await storyblokApi.get(`cdn/stories/${authPageSlug}`, sbParams)

  let mainMenuSlugAlternates = constructMainMenuSlugAlternates(locale)

  return {
    props: {
      story: story,
      key: storyblokData ? storyblokData.story.id : false,
      mainMenu: mainMenu.data ? mainMenu.data.story : false,
      mainMenuSlugAlternates,
      footer: footer.data ? footer.data.story : false,
      authPage: authPage.data ? authPage.data.story : false,
      locale: locale,
      ...(await serverSideTranslations(locale ?? 'nl', ['common', 'mainmenu'])),
    },
    revalidate: process.env.APP_ENV === 'prd' ? 5 * 60 : 60, // prd
  }
}
